<template>
    <!--<div>asd {{ empresaSelected }}</div>-->
    <div><multiselect class="mb-2"
        open-direction="bottom"
        placeholder="Buscar Trabajador"
        track-by="nombreTrabajador"
        select-label="" 
        selectedLabel="" 
        deselect-label=""
        :hide-selected="true"
        :loading="isLoadingTrabajadoresFiltros"
        :multiple="true"
        :searchable="true"
        :internal-search="false"
        :disabled="empresaSelected == null"
        :custom-label="customTrabajador"
        :id="empresaSelected != null ? empresaSelected : 'sinEmpresa'"
        v-model="trabajadoresSelectedFiltro"
        :options="trabajadoresFiltro"
        @search-change="busquedaFiltroTrabajador"
        @input="seleccionarTrabajadorFiltro"
        @remove="quitarTrabajadorFiltro"
        >
        <span slot="noOptions">No hay trabajadores para buscar</span>
        <span slot="noResult">No se han encontrado trabajadores con esos datos</span>
        <!--<template slot="selection" slot-scope="{ values, search, isOpen }">
            <span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} trabajadores filtrados</span>
        </template>-->
  
        <template slot="afterList">
            <div v-if="hasNextPage(empresaSelected)"
                v-observe-visibility="(isVisible, entry) => !isLoadingTrabajadoresFiltros ? reachedEndOfList(isVisible, entry) : ''">
            </div>
        </template>

    </multiselect>
    </div>
    <!--<multiselect class="mt-2 mb-2" 
        :id="empresaSelected != null ? empresaSelected : 'sinEmpresa'"
        :disabled="empresaSelected == null" 
        v-model="trabajadoresSelectedFiltro"
        :custom-label="customTrabajador" select-label="" selectedLabel="" deselect-label=""
        track-by="nombreTrabajador" placeholder="Buscar Trabajador" open-direction="bottom"
        :options="trabajadoresFiltro" :multiple="true" :searchable="true" :internal-search="false"
        @search-change="asyncFind" @select="seleccionarTrabajador" @remove="quitarTrabajador">
        <template slot="afterList">
            <div v-if="hasNextPage(empresaSelected)"
                v-observe-visibility="(isVisible, entry) => !isLoadingTrabajadoresFiltros ? reachedEndOfList(isVisible, entry, selempresa) : ''">
            </div>
        </template>
        <span slot="noResult">No se han encontrado trabajadores con esos datos</span>
    </multiselect>-->
    
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    import cons from "@/const";
    import Multiselect from "vue-multiselect";
    import axios from "axios";
    
    import * as util from '@/views/utils/utilsFunctions'
    import apiUrls from '@/views/utils/apiUrls'

    export default {
        name: "FiltroTrabajadores",
        props: {
            empresaSelected: Object,
            tipoDocSelected: Object,
            mesSelected: Object,
            anioSelected: Object
        },
        components: {
            Multiselect
        },
        data() {
            return {
                tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
                urlBase: cons.port + "://" + cons.ipServer,

                trabPorPag: 10,

                isLoadingTrabajadoresFiltros: false,

                trabajadoresSelectedFiltro: [],
                trabajadoresFiltro: [],
                rqstTrabs: null,
                strFiltroTrabajadores: "",

                paginaActualTrabajadores: 1,
                totalPaginas: 1
            

            };
        },
        created: function () {
        },
        beforeMount() {
            
        },
        methods: {
            seleccionarTrabajadorFiltro: function (trab, empresa) {
                this.$emit('seleccionarTrab', this.trabajadoresSelectedFiltro)
                
            },
            quitarTrabajadorFiltro: function (trab, empresa) {
                this.$emit('seleccionarTrab', this.trabajadoresSelectedFiltro)
            },
            busquedaFiltroTrabajador: function (query, empresa) {
                this.strFiltroTrabajadores = query;
                this.trabajadoresFiltro = [
                    {
                        rutTrabajador: "0",
                        nombreTrabajador: "Buscando...",
                        documento: { hayDocumento: false },
                        $isDisabled: true,
                    },
                ];
                this.getTrabajadores();
            },
            hasNextPage() {
                if(this.empresaSelected != null){
                    return this.paginaActualTrabajadores <= this.totalPaginas;
                }
            },
            reachedEndOfList(reached, entry) {
                if (this.empresaSelected != null)
                    if (reached) {
                        if (this.hasNextPage()) this.getTrabajadores();
                    }
            },
            getTrabajadores: function(){
                this.isLoadingTrabajadoresFiltros = true;
                
                let url = `${this.urlBase}${apiUrls.general.getTrabajadoresEmpresa}${this.empresaSelected.idEmpresa}/${this.tipoDocSelected.valor}/${this.mesSelected != null ? this.mesSelected.valor : 'null'}/${this.anioSelected != null ? this.anioSelected.valor : 'null'}`
                
                if (this.rqstTrabs != null) {
                    this.rqstTrabs.cancel();
                    this.rqstTrabs.msg = "Cancelado";
                    this.rqstTrabs = null;
                }
                const axiosSource = axios.CancelToken.source();
                this.rqstTrabs = { cancel: axiosSource.cancel, msg: "Cargando..." };
                if(this.trabajadoresFiltro.length==0){
                    this.trabajadoresFiltro = [
                        {
                            rutTrabajador: "0",
                            nombreTrabajador: "Buscando...",
                            documento: { hayDocumento: false },
                            $isDisabled: true,
                        },
                    ];
                }
                axios({
                    method: "POST",
                    url: url,
                    cancelToken: axiosSource.token,
                    data: {
                        paginacionTrabajadores: {
                            strFiltro: this.strFiltroTrabajadores,
                            actual: this.paginaActualTrabajadores,
                            tamanioPagina: this.trabPorPag,
                        },
                    },
                    headers: {
                        Authorization: `${this.tokenLogin}`,
                    },
                }).then((result) => {
                        this.totalPaginas = Number((result.data.totalTrabajadores / this.trabPorPag).toFixed(0));
                        
                        if (this.trabajadoresFiltro.length == 1 && this.trabajadoresFiltro[0].rutTrabajador == "0") {
                            this.trabajadoresFiltro = [];
                        }
                        this.trabajadoresFiltro = this.trabajadoresFiltro.concat(result.data.trabajadores)
                        this.paginaActualTrabajadores++
                    },
                    (error) => {

                    }
                )
                .finally(() => {
                    this.isLoadingTrabajadoresFiltros = false;
                    this.rqstTrabs = null;
                });
            },
            
            customTrabajador: function (trabajador) {
                if (trabajador.rutTrabajador == "0") {
                    return "Buscando...";
                } else {
                    let sinDoc = ""// revisar por que no trae documentos trabajador.documentos > 0 ? "" : "(Sin Documento)"
                    return `${util.formatearRut(trabajador.rutTrabajador)} – ${trabajador.nombreTrabajador} ${sinDoc}`;
                }
            },

            cualquierWea: function () {
                console.log("algo!")
            }
        },
    };
</script>


<style scoped>
</style>