var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.trabajador.documentos.length==0)?_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:({ 
            placement: 'top',
            arrow: true, 
            arrowType: 'round', 
            animation:'fade',
            theme: 'google',
            followCursor : true,
        }),expression:"{ \n            placement: 'top',\n            arrow: true, \n            arrowType: 'round', \n            animation:'fade',\n            theme: 'google',\n            followCursor : true,\n        }"}],attrs:{"content":"Sin Documento"}},[_c('CIcon',{attrs:{"content":_vm.$options.freeSet.cilMeh}})],1):_c('span',[(_vm.mostrarAlertas)?_c('span',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.trabajador.tieneMailTrabajador && !_vm.trabajador.modificarMail),expression:"!trabajador.tieneMailTrabajador && !trabajador.modificarMail"},{name:"tippy",rawName:"v-tippy",value:({ 
                    placement: 'top',
                    arrow: true, 
                    arrowType: 'round', 
                    animation:'fade',
                    theme: 'google',
                    followCursor : true,
                }),expression:"{ \n                    placement: 'top',\n                    arrow: true, \n                    arrowType: 'round', \n                    animation:'fade',\n                    theme: 'google',\n                    followCursor : true,\n                }"}],attrs:{"content":"El trabajador no tiene correo"}},[_c('CIcon',{staticClass:"ml-2",staticStyle:{"color":"#e55353"},attrs:{"content":_vm.$options.freeSet.cilWarning}})],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.revisarEstadoDoc(_vm.trabajador.documentos)),expression:"revisarEstadoDoc(trabajador.documentos)"},{name:"tippy",rawName:"v-tippy",value:({ 
                    placement: 'top',
                    arrow: true, 
                    arrowType: 'round', 
                    animation:'fade',
                    theme: 'google',
                    followCursor : true,
                }),expression:"{ \n                    placement: 'top',\n                    arrow: true, \n                    arrowType: 'round', \n                    animation:'fade',\n                    theme: 'google',\n                    followCursor : true,\n                }"}],attrs:{"content":"Hay documentos sin aceptar"}},[_c('CIcon',{staticClass:"ml-2",staticStyle:{"color":"#f9b115"},attrs:{"content":_vm.$options.freeSet.cilWarning}})],1)]):_vm._e(),(_vm.mostrarEnvioCorreo)?_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.revisarEstadoDoc(_vm.trabajador.documentos)),expression:"!revisarEstadoDoc(trabajador.documentos)"},{name:"tippy",rawName:"v-tippy",value:({ 
                placement: 'top',
                arrow: true, 
                arrowType: 'round', 
                animation:'fade',
                theme: 'google',
                followCursor : true,
            }),expression:"{ \n                placement: 'top',\n                arrow: true, \n                arrowType: 'round', \n                animation:'fade',\n                theme: 'google',\n                followCursor : true,\n            }"}],attrs:{"content":_vm.mensajeEnviarCorreo(_vm.trabajador)}},[_c('CButton',{staticClass:"btnPrincipalVapp ml-2",staticStyle:{"font-size":"11px"},attrs:{"disabled":_vm.trabajador.isEnviandoMailTrab || !_vm.trabajador.tieneMailTrabajador || _vm.isLoadingEnvioTodos},on:{"click":function($event){return _vm.enviarDocumentoTrabajador(_vm.trabajador, _vm.empresa.idEmpresa)}}},[_c('CIcon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.trabajador.isEnviandoMailTrab),expression:"!trabajador.isEnviandoMailTrab"}],attrs:{"content":_vm.$options.freeSet.cilEnvelopeOpen}}),_c('CIcon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.trabajador.isEnviandoMailTrab),expression:"!trabajador.isEnviandoMailTrab"}],attrs:{"content":_vm.$options.freeSet.cilExpandRight}}),_c('CIcon',{directives:[{name:"show",rawName:"v-show",value:(_vm.trabajador.isEnviandoMailTrab),expression:"trabajador.isEnviandoMailTrab"}],staticClass:"imgr",attrs:{"content":_vm.$options.freeSet.cilLoopCircular}})],1)],1):_vm._e()]),(_vm.mostrarEnvioCorreo)?_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.portContador),expression:"portContador"}]},[_c('span',[_c('CButtonGroup',{directives:[{name:"show",rawName:"v-show",value:(!_vm.trabajador.isModificandoMail && !_vm.trabajador.modificarMail),expression:"!trabajador.isModificandoMail && !trabajador.modificarMail"},{name:"tippy",rawName:"v-tippy",value:({ 
                    placement: 'top',
                    arrow: true, 
                    arrowType: 'round', 
                    animation:'fade',
                    theme: 'google',
                    followCursor : true,
                }),expression:"{ \n                    placement: 'top',\n                    arrow: true, \n                    arrowType: 'round', \n                    animation:'fade',\n                    theme: 'google',\n                    followCursor : true,\n                }"}],staticClass:"mx-1",attrs:{"size":"sm","disaled":_vm.trabajador.isEnviandoMailTrab,"content":(_vm.trabajador.modificarMail)?'Cancelar':'Modificar Correo'}},[_c('CButton',{attrs:{"color":"warning","size":"sm"},on:{"click":function($event){_vm.trabajador.modificarMail = true}}},[_c('CIcon',{attrs:{"name":"cil-pencil"}})],1)],1)],1),_c('span',[_c('CInput',{directives:[{name:"show",rawName:"v-show",value:(_vm.trabajador.modificarMail && !_vm.trabajador.isModificandoMail),expression:"trabajador.modificarMail && !trabajador.isModificandoMail"}],attrs:{"type":"text","horizontal":"","placeholder":"Correo","is-valid":_vm.trabajador.mailValido},on:{"keyup":function($event){return _vm.validarMail(_vm.trabajador)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('CButton',{directives:[{name:"tippy",rawName:"v-tippy",value:({ 
                            placement: 'top',
                            arrow: true, 
                            arrowType: 'round', 
                            animation:'fade',
                            theme: 'google',
                            followCursor : true,
                        }),expression:"{ \n                            placement: 'top',\n                            arrow: true, \n                            arrowType: 'round', \n                            animation:'fade',\n                            theme: 'google',\n                            followCursor : true,\n                        }"}],attrs:{"color":"success","size":"sm","disabled":!_vm.trabajador.mailValido,"content":"Guardar Correo"},on:{"click":function($event){return _vm.cambiarCorreoTrabajador(_vm.trabajador, _vm.empresa.idEmpresa)}}},[_c('CIcon',{attrs:{"name":"cil-check-circle"}})],1),_c('CButton',{directives:[{name:"tippy",rawName:"v-tippy",value:({ 
                            placement: 'top',
                            arrow: true, 
                            arrowType: 'round', 
                            animation:'fade',
                            theme: 'google',
                            followCursor : true,
                        }),expression:"{ \n                            placement: 'top',\n                            arrow: true, \n                            arrowType: 'round', \n                            animation:'fade',\n                            theme: 'google',\n                            followCursor : true,\n                        }"},{name:"show",rawName:"v-show",value:(_vm.trabajador.modificarMail),expression:"trabajador.modificarMail"}],attrs:{"content":(_vm.trabajador.modificarMail)?'Cancelar':'Modificar Correo',"color":"danger","size":"sm"},on:{"click":function($event){_vm.trabajador.modificarMail = false}}},[_c('CIcon',{attrs:{"name":"cil-x-circle"}})],1)]},proxy:true}],null,false,2971826461),model:{value:(_vm.trabajador.mailModificado),callback:function ($$v) {_vm.$set(_vm.trabajador, "mailModificado", $$v)},expression:"trabajador.mailModificado"}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }