<template>
    <div>
        <CRow>
            <CCol md="12">
                <a-table
                    :style="{ 'min-height': '50px' }"
                    :indentSize="10"
                    row-key="rutTrabajador"
                    :locale="localeTrabajadores"
                    :columns="columnasTrabajadores"
                    :data-source="trabajadores"
                    :pagination="paginacion"
                    :scroll="{ y: 400 }"
                    size="small"
                    @change="handleTableChange">
                        <span slot="tituloDoc">
                            <span class="text-truncate" style="overflow:hidden">Dctos.</span>
                            <span>({{totalTrabajadoresDocumentos}}/{{totalTrabajadores}})</span>
                        </span>
                        <span slot="rutTrabajador" slot-scope="trabajador">
                            {{$utils.formatearRut(trabajador.rutTrabajador)}}
                        </span>
                        <span slot="documento" slot-scope="trabajador">

                            <div>
                                <div v-if="tipoDocumentoSelected.valor == 'liquidaciones' && (!portContador ? (trabajador.documento != null?(trabajador.documento.estadoDocumento == 1 || trabajador.documento.estadoDocumento == 3):true) : true)">
                                    <span v-if="(!portContador ? (trabajador.documento != null?(trabajador.documento.estadoDocumento == 1 || trabajador.documento.estadoDocumento == 3):true) : true)">
                                        <div v-if="trabajador.documento == null || !trabajador.documento.hayDocumento">
                                            <CButton
                                                size="sm" variant="ghost"
                                                content="Sin Documento"
                                                    v-tippy="{ 
                                                        placement: 'top',
                                                        arrow: true, 
                                                        arrowType: 'round', 
                                                        animation:'fade',
                                                        theme: 'google',
                                                        followCursor : true,
                                                    }"
                                                >
                                                <CIcon :content="$options.freeSet.cilMeh" />
                                            </CButton>
                                        </div>
                                        <div v-else>
                                            <a 
                                                :href="trabajador.documento.urlDocumento" 
                                                target="_blank" 
                                                style="text-decoration: none; color:#0b7689;" 
                                                content="Descargar Documento"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }"
                                            >
                                                <CIcon :content="$options.freeSet.cilCloudDownload" />
                                            </a>
                                            /
                                            <a
                                                style="text-decoration: none; color:#0b7689" 
                                                @click="seleccionarTrabajadores(empresa, trabajador, trabajador.documento)"
                                                content="Ver Documento"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }"
                                            >
                                                <CIcon :content="$options.freeSet.cilZoomIn" />
                                            </a>
                                        </div>
                                    </span>
                                </div>
                                <div v-else-if="tipoDocumentoSelected.valor == 'contratos' && (!portContador ? (trabajador.documento != null?(trabajador.documento.estadoDocumento == 1 || trabajador.documento.estadoDocumento == 3):true) : true)">
                                    <div v-if="(trabajador.documento != null ? !portContador ? trabajador.documento.estadoDocumento == 1 || trabajador.documento.estadoDocumento == 3 : true : false)">
                                    
                                        <CRow v-if="!isLoadingGenerarDocumento && !trabajador.isLoadingGenerarDocumento">
                                            <CCol>
                                                <span v-if="trabajador.ultimosDocumentos.ultimoContrato == undefined && trabajador.ultimosDocumentos.ultimoAnexo == undefined && trabajador.ultimosDocumentos.ultimoFinq == undefined">
                                                    <CButton
                                                        size="sm" variant="ghost"
                                                        content="Sin Documento"
                                                            v-tippy="{ 
                                                                placement: 'top',
                                                                arrow: true, 
                                                                arrowType: 'round', 
                                                                animation:'fade',
                                                                theme: 'google',
                                                                followCursor : true,
                                                            }"
                                                        >
                                                        <CIcon
                                                            :content="$options.freeSet.cilMeh"
                                                        />
                                                    </CButton>
                                                </span>
                                                <span v-if="trabajador.ultimosDocumentos.ultimoContrato != undefined">
                                                    <a 
                                                        v-show="trabajador.ultimosDocumentos != undefined && trabajador.ultimosDocumentos.ultimoContrato != undefined" 
                                                        @click="descargarDocumento(trabajador.ultimosDocumentos.ultimoContrato)"
                                                        :href="trabajador.ultimosDocumentos.ultimoContrato.urlDocumento" 
                                                        target="_blank" 
                                                        style="text-decoration: none; color:#0b7689;" 
                                                        content="Descargar Documento Contrato"
                                                        v-tippy="{ 
                                                            placement: 'top',
                                                            arrow: true, 
                                                            arrowType: 'round', 
                                                            animation:'fade',
                                                            theme: 'google',
                                                            followCursor : true,
                                                        }"
                                                    >
                                                        <CIcon :content="$options.freeSet.cilCloudDownload" />
                                                    </a>
                                                    /
                                                    <a
                                                        style="text-decoration: none; color:#0b7689" 
                                                        v-show="trabajador.ultimosDocumentos != undefined && trabajador.ultimosDocumentos.ultimoContrato != undefined"
                                                        @click="seleccionarTrabajadores(empresa, trabajador, trabajador.ultimosDocumentos.ultimoContrato)"
                                                        content="Ver Documento Contrato"
                                                        v-tippy="{ 
                                                            placement: 'top',
                                                            arrow: true, 
                                                            arrowType: 'round', 
                                                            animation:'fade',
                                                            theme: 'google',
                                                            followCursor : true,
                                                        }"
                                                        >
                                                        <CIcon :content="$options.freeSet.cilZoomIn" />
                                                    </a>
                                                </span>
                                                <span v-if="trabajador.ultimosDocumentos.ultimoAnexo != undefined">
                                                    <span v-show="trabajador.ultimosDocumentos.ultimoContrato != undefined"> | </span>
                                                    <a 
                                                        v-show="trabajador.ultimosDocumentos != undefined 
                                                        && trabajador.ultimosDocumentos.ultimoAnexo != undefined" 
                                                        @click="descargarDocumento(trabajador.ultimosDocumentos.ultimoAnexo)"
                                                        :href="trabajador.ultimosDocumentos.ultimoAnexo.urlDocumento" 
                                                        target="_blank" 
                                                        style="text-decoration: none; color:#0b7689;" 
                                                        content="Descargar Documento Anexo"
                                                        v-tippy="{ 
                                                            placement: 'top',
                                                            arrow: true, 
                                                            arrowType: 'round', 
                                                            animation:'fade',
                                                            theme: 'google',
                                                            followCursor : true,
                                                        }">
                                                        <CIcon :content="$options.freeSet.cilCloudDownload" />
                                                    </a>
                                                    /
                                                    <a
                                                        style="text-decoration: none; color:#0b7689" 
                                                        @click="seleccionarTrabajadores(empresa, trabajador, trabajador.ultimosDocumentos.ultimoAnexo)"
                                                        v-show="trabajador.ultimosDocumentos != undefined && 
                                                        trabajador.ultimosDocumentos.ultimoAnexo != undefined"
                                                        content="Ver Documento Anexo"
                                                        v-tippy="{ 
                                                            placement: 'top',
                                                            arrow: true, 
                                                            arrowType: 'round', 
                                                            animation:'fade',
                                                            theme: 'google',
                                                            followCursor : true,
                                                        }"
                                                        >
                                                        <CIcon :content="$options.freeSet.cilZoomIn" />
                                                    </a>
                                                </span>
                                                <span v-if="trabajador.ultimosDocumentos.ultimoFinq != undefined">
                                                    <span v-show="trabajador.documento.estadoDocumento != 1 && trabajador.documento.estadoDocumento !=3 && portContador && !periodoCerrado"> | </span>
                                                    <a 
                                                        v-show="trabajador.ultimosDocumentos != undefined && trabajador.ultimosDocumentos.ultimoFinq != undefined" 
                                                        @click="descargarDocumento(trabajador.ultimosDocumentos.ultimoFinq)"
                                                        :href="trabajador.ultimosDocumentos.ultimoFinq.urlDocumento" 
                                                        target="_blank" 
                                                        style="text-decoration: none; color:#0b7689;" 
                                                        content="Descargar Documento Finiquito"
                                                        v-tippy="{ 
                                                            placement: 'top',
                                                            arrow: true, 
                                                            arrowType: 'round', 
                                                            animation:'fade',
                                                            theme: 'google',
                                                            followCursor : true,
                                                        }"
                                                    >
                                                        <CIcon :content="$options.freeSet.cilCloudDownload" />
                                                    </a>
                                                    /
                                                    <a
                                                        style="text-decoration: none; color:#0b7689" 
                                                        v-show="trabajador.ultimosDocumentos != undefined && 
                                                        trabajador.ultimosDocumentos.ultimoFinq != undefined"
                                                        @click="seleccionarTrabajadores(empresa, trabajador, trabajador.ultimosDocumentos.ultimoFinq)"
                                                        content="Ver Documento Finiquito"
                                                        v-tippy="{ 
                                                            placement: 'top',
                                                            arrow: true, 
                                                            arrowType: 'round', 
                                                            animation:'fade',
                                                            theme: 'google',
                                                            followCursor : true,
                                                        }"
                                                        >
                                                        <CIcon :content="$options.freeSet.cilZoomIn" />
                                                    </a>
                                                </span>
                                            </CCol>
                                        </CRow>
                                        <span v-else>
                                            <CIcon :content="$options.freeSet.cilLoopCircular" class="imgr" />
                                        </span>
                                    </div>
                                    <div v-else>
                                        <span v-if="isLoadingGenerarDocumento || trabajador.isLoadingGenerarDocumento">
                                            <CIcon :content="$options.freeSet.cilLoopCircular" class="imgr" />
                                        </span>
                                        <span v-else>
                                            <!--<a 
                                                v-show="tipoDocumentoSelected.permiteGenerarDoc"
                                                @click="generarDocumentoTrabajador(empresa, trabajador)"
                                                target="_blank" 
                                                style="text-decoration: none; color:#0b7689;" 
                                                content="Generar Documentos"
                                                    v-tippy="{ 
                                                        placement: 'top',
                                                        arrow: true, 
                                                        arrowType: 'round', 
                                                        animation:'fade',
                                                        theme: 'google',
                                                        followCursor : true,
                                                    }"
                                                >
                                                <CIcon :content="$options.freeSet.cilLibraryAdd" />
                                            </a>-->
                                            <CButton
                                                size="sm" variant="ghost"
                                                content="Sin Documento"
                                                    v-tippy="{ 
                                                        placement: 'top',
                                                        arrow: true, 
                                                        arrowType: 'round', 
                                                        animation:'fade',
                                                        theme: 'google',
                                                        followCursor : true,
                                                    }"
                                                >
                                                <CIcon :content="$options.freeSet.cilMeh" />
                                            </CButton>
                                            </span>
                                    </div>
                                </div>
                                <div v-else>
                                    <CButton
                                    size="sm" variant="ghost"
                                    content="Sin Documento"
                                        v-tippy="{ 
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                            followCursor : true,
                                        }"
                                    >
                                    <CIcon :content="$options.freeSet.cilMeh" />
                                </CButton>
                                </div>
                            </div>
                        </span>
                        <span slot="envioCorreo" slot-scope="trabajador">
                            <OpcionesEnvioCorreo
                                :mostrarAlertas="true"
                                :mostrarEnvioCorreo="true"
                                :empresa="empresa"
                                :portContador="portContador"
                                :isLoadingEnvioTodos="empresa.isLoadingEnvioTodos"
                                :trabajador="trabajador"
                                :tipoDocumentos="tipoDocumentoSelected"
                                :mesSelected="mesSelected"
                                :anioSelected="periodoSelected"
                                :filtrarPorPeriodo="mostrarPeriodo"
                            />
                            <!--<div v-if="tipoDocumentoSelected.valor == 'contratos'">
                                
                                <CButton
                                    v-show="trabajador.ultimosDocumentos == null || (trabajador.ultimosDocumentos.ultimoAnexo == null && trabajador.ultimosDocumentos.ultimoContrato == null)"
                                    size="sm" variant="ghost"
                                    content="Sin Documentos"
                                        v-tippy="{ 
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                            followCursor : true,
                                        }"
                                    >
                                    <CIcon
                                        :content="$options.freeSet.cilMeh"
                                    />
                                </CButton>
                                <CButton
                                    v-show="!trabajador.tieneMailTrabajador && !trabajador.modificarMail"
                                    class="ml-2"
                                    size="sm" variant="ghost"
                                    style="color:#e55353"
                                    content="El trabajador no tiene correo"
                                        v-tippy="{ 
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                            followCursor : true,
                                        }"
                                    >
                                    <CIcon
                                        :content="$options.freeSet.cilWarning"
                                    />
                                </CButton>
                                <CInput
                                    v-model="trabajador.mailModificado"
                                    type="text"
                                    horizontal
                                    placeholder="Correo"
                                    :is-valid="trabajador.mailValido"
                                    v-show="trabajador.modificarMail && !trabajador.isModificandoMail"
                                    @keyup="validarMail(trabajador)">
                                    <template #append>
                                        <CButton 
                                            color="success" 
                                            size="sm"
                                            :disabled="!trabajador.mailValido"
                                            @click="cambiarCorreoTrabajador(trabajador, empresa.idEmpresa)"
                                            content="Guardar Correo"
                                            v-tippy="{ 
                                                placement: 'top',
                                                arrow: true, 
                                                arrowType: 'round', 
                                                animation:'fade',
                                                theme: 'google',
                                                followCursor : true,
                                            }">
                                            <CIcon name="cil-check-circle"/>
                                        </CButton>

                                        <CButton
                                            :content="(trabajador.modificarMail)?'Cancelar':'Modificar Correo'"
                                            v-tippy="{ 
                                                placement: 'top',
                                                arrow: true, 
                                                arrowType: 'round', 
                                                animation:'fade',
                                                theme: 'google',
                                                followCursor : true,
                                            }"
                                            v-show="trabajador.modificarMail"
                                            color="danger" 
                                            @click="trabajador.modificarMail = false" size="sm" ><CIcon name="cil-x-circle"/>
                                        </CButton>
                                    </template>
                                </CInput>
                                    <CButton
                                        v-if="trabajador.ultimosDocumentos.ultimoContrato != undefined || trabajador.ultimosDocumentos.ultimoAnexo != undefined"
                                        size="sm"
                                        class="btnPrincipalVapp"
                                        v-show="((trabajador.ultimosDocumentos.ultimoContrato != undefined ? (trabajador.ultimosDocumentos.ultimoContrato.estadoDocumento != 1 || trabajador.ultimosDocumentos.ultimoContrato.estadoDocumento ==3) : false) || (trabajador.ultimosDocumentos.ultimoAnexo != undefined ? (trabajador.ultimosDocumentos.ultimoAnexo.estadoDocumento != 1 || trabajador.ultimosDocumentos.ultimoAnexo.estadoDocumento ==3) : false)) && !trabajador.modificarMail && trabajador.tieneMailTrabajador"
                                        @click="enviarDocumentosTrabajadorModal(trabajador)">
                                        Enviar a trabajador
                                    </CButton>
                                <CButtonGroup size="sm" class="mx-1"
                                    v-show="!trabajador.isModificandoMail && !trabajador.modificarMail"
                                        :content="(trabajador.modificarMail)?'Cancelar':'Modificar Correo'"
                                        v-tippy="{ 
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                            followCursor : true,
                                        }">
                                        
                                    <CButton 
                                        color="warning" size="sm"
                                        @click="trabajador.modificarMail = true">
                                        <CIcon name="cil-pencil"/>
                                    </CButton>
                                </CButtonGroup>
                            </div>
                            <div v-else-if="tipoDocumentoSelected.valor == 'liquidaciones'">
                                
                                <CButton
                                    v-if="trabajador.documento == null || (trabajador.documento != null && !trabajador.documento.hayDocumento)"
                                    size="sm" variant="ghost"
                                    content="Sin Documento"
                                        v-tippy="{ 
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                            followCursor : true,
                                        }"
                                    >
                                    <CIcon :content="$options.freeSet.cilMeh" />
                                </CButton>
                                <div v-else>
                                    <CButton
                                        v-show="trabajador.documento.hayDocumento && !trabajador.tieneMailTrabajador && !trabajador.modificarMail"
                                        class="ml-2"
                                        size="sm" variant="ghost"
                                        style="color:#e55353"
                                        content="El trabajador no tiene correo"
                                            v-tippy="{ 
                                                placement: 'top',
                                                arrow: true, 
                                                arrowType: 'round', 
                                                animation:'fade',
                                                theme: 'google',
                                                followCursor : true,
                                            }"
                                        >
                                        <CIcon
                                            :content="$options.freeSet.cilWarning"
                                        />
                                    </CButton>
                                    <CButton
                                        v-show="trabajador.documento.hayDocumento && trabajador.documento.estadoDocumento != 3 && !trabajador.modificarMail"
                                        class="ml-2"
                                        size="sm" variant="ghost"
                                        style="color:#f9b115"
                                        content="El documento no está aceptado"
                                            v-tippy="{ 
                                                placement: 'top',
                                                arrow: true, 
                                                arrowType: 'round', 
                                                animation:'fade',
                                                theme: 'google',
                                                followCursor : true,
                                            }"
                                        >
                                        <CIcon
                                            :content="$options.freeSet.cilWarning"
                                        />
                                    </CButton>
                                    
                                    
                                    <CButton
                                        content="Este documento ya a sido enviado al trabajador, volver a enviar"
                                        v-tippy="{ 
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                            followCursor : true,
                                        }"
                                        style="font-size:11px;"
                                        class="btnPrincipalVapp"
                                        :disabled="trabajador.documento.estadoDocumento != '3' || empresa.isLoadingEnvioTodos"
                                        v-show="(empresa.estado == 1 || empresa.estado ==3) && trabajador.documento.hayDocumento && trabajador.documento.enviadoTrabajador && !trabajador.documento.isLoadiongEnvio && !trabajador.modificarMail && trabajador.tieneMailTrabajador"
                                        @click="enviarDocumentoTrabajador(trabajador, empresa.idEmpresa)">
                                        <CIcon :content="$options.freeSet.cilEnvelopeOpen"  />
                                        <CIcon :content="$options.freeSet.cilExpandRight"  />
                                    </CButton>
                                    <CButton
                                        size="sm"
                                        :content="(trabajador.documento.estadoDocumento != 3)?'El documento debe estar aceptado':'Documento listo para enviar a trabajador'"
                                        v-tippy="{ 
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                            followCursor : true,
                                        }"
                                        class="btnPrincipalVapp"
                                        :disabled="trabajador.documento.estadoDocumento != 3 || empresa.isLoadingEnvioTodos"
                                        v-show="(empresa.estado == 1 || empresa.estado ==3) && trabajador.documento.hayDocumento && !trabajador.documento.enviadoTrabajador && !trabajador.documento.isLoadiongEnvio && !trabajador.modificarMail && trabajador.tieneMailTrabajador"
                                        @click="enviarDocumentoTrabajador(trabajador, empresa.idEmpresa)">
                                        Enviar a trabajador
                                    </CButton>
                                                            
                                    <CButton
                                        :disabled="true"
                                        class="btnNormalVapp2"
                                        v-show="trabajador.documento.isLoadiongEnvio || trabajador.isModificandoMail">
                                        <span v-show="trabajador.documento.isLoadiongEnvio"><CIcon :content="$options.freeSet.cilLoopCircular" class="imgr" /> enviando</span>
                                        <span v-show="trabajador.isModificandoMail"><CIcon :content="$options.freeSet.cilLoopCircular" class="imgr" /> Modificando</span>
                                    </CButton>
                                                            
                                                            

                                    <CInput
                                        v-model="trabajador.mailModificado"
                                        type="text"
                                        horizontal
                                        placeholder="Correo"
                                        :is-valid="trabajador.mailValido"
                                        v-show="trabajador.modificarMail && !trabajador.isModificandoMail"
                                        @keyup="validarMail(trabajador)">
                                        <template #append>
                                            <CButton 
                                                color="success" 
                                                size="sm"
                                                :disabled="!trabajador.mailValido"
                                                @click="cambiarCorreoTrabajador(trabajador, empresa.idEmpresa)"
                                                content="Guardar Correo"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }">
                                                <CIcon name="cil-check-circle"/>
                                            </CButton>

                                            <CButton
                                                :content="(trabajador.modificarMail)?'Cancelar':'Modificar Correo'"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }"
                                                v-show="trabajador.modificarMail"
                                                color="danger" 
                                                @click="trabajador.modificarMail = false" size="sm" ><CIcon name="cil-x-circle"/>
                                            </CButton>
                                        </template>
                                    </CInput>
                                                            
                                                            
                                                            <CButtonGroup size="sm" class="mx-1"
                                                                v-show="portContador && !trabajador.documento.isLoadiongEnvio && !trabajador.isModificandoMail && !trabajador.modificarMail"
                                                                :content="(trabajador.modificarMail)?'Cancelar':'Modificar Correo'"
                                                                v-tippy="{ 
                                                                    placement: 'top',
                                                                    arrow: true, 
                                                                    arrowType: 'round', 
                                                                    animation:'fade',
                                                                    theme: 'google',
                                                                    followCursor : true,
                                                                }"
                                                            >
                                                                
                                                                <CButton 
                                                                    color="warning" size="sm"
                                                                    @click="trabajador.modificarMail = true"
                                                                >
                                                                    <CIcon name="cil-pencil"/>
                                                                </CButton>
                                                            </CButtonGroup>
                                                        </div>
                                </div>
                            -->
                        </span>

                        <div v-if="tipoDocumentoSelected.valor == 'liquidaciones'"
                            slot="expandedRowRender"
                            slot-scope="trabajador" style="width:99%; margin: 0">
                            <CRow>
                                <CCol>
                                    <a-table
                                        row-key="idDocumento"
                                        :columns="columnasArchivosLiquidaciones" 
                                        :data-source="trabajador.documentos"
                                        :scroll="{ y: 200 }"
                                        class="tableInterior mt-3" 
                                        size="small">
                                        <div slot="nombreArchivo" slot-scope="documento">
                                            {{documento.nombreDocumento}}
                                        </div>
                                        <div slot="fechaEmision" slot-scope="documento">
                                            {{$utils.formatFechaDocumento(documento.ultimaGeneracion)}}
                                        </div>
                                        <div slot="documentos" slot-scope="documento">
                                            <!--Validar cuando ocultarlos!!!-->
                                            <span v-if="documento.isLoadingDoc">
                                                <CIcon style="color:#0b7689;" :content="$options.freeSet.cilLoopCircular" class="imgr" />
                                            </span>
                                            
                                            <span v-else-if="(!portContador ? (trabajador.documento != null?(trabajador.documento.estadoDocumento == 1 || trabajador.documento.estadoDocumento == 3):true) : true)">
                                                <span>
                                                    <a 
                                                        :href="documento.urlDocumento" 
                                                        target="_blank" 
                                                        style="text-decoration: none; color:#0b7689;" 
                                                        content="Descargar Documento"
                                                        v-tippy="{ 
                                                            placement: 'top',
                                                            arrow: true, 
                                                            arrowType: 'round', 
                                                            animation:'fade',
                                                            theme: 'google',
                                                            followCursor : true,
                                                        }"
                                                    >
                                                        <CIcon :content="$options.freeSet.cilCloudDownload" />
                                                    </a>
                                                </span>
                                                <span>
                                                    |
                                                    <a  
                                                        style="text-decoration: none; color:#0b7689" 
                                                        content="Ver Documento"
                                                        v-tippy="{ 
                                                            placement: 'top',
                                                            arrow: true, 
                                                            arrowType: 'round', 
                                                            animation:'fade',
                                                            theme: 'google',
                                                            followCursor : true,
                                                        }"
                                                        @click="seleccionarTrabajadores(empresa, trabajador, documento)">
                                                        <CIcon :content="$options.freeSet.cilZoomIn" />
                                                    </a>
                                                </span>
                                                <span v-if="(portContador)?documento.estadoDocumento != 1 && documento.estadoDocumento != 3 : false">
                                                    |
                                                    <a  
                                                        style="text-decoration: none; color:#0b7689" 
                                                        content="Eliminar Documento"
                                                        v-tippy="{ 
                                                            placement: 'top',
                                                            arrow: true, 
                                                            arrowType: 'round', 
                                                            animation:'fade',
                                                            theme: 'google',
                                                            followCursor : true,
                                                        }"
                                                        @click="eliminarDocumento(empresa, trabajador, documento)">
                                                        <CIcon name="cil-x-circle"/>
                                                    </a>
                                                </span>
                                            </span>
                                            <span v-else>
                                                <CButton
                                                size="sm" variant="ghost"
                                                content="Sin Documento"
                                                    v-tippy="{ 
                                                        placement: 'top',
                                                        arrow: true, 
                                                        arrowType: 'round', 
                                                        animation:'fade',
                                                        theme: 'google',
                                                        followCursor : true,
                                                    }"
                                                >
                                                    <CIcon :content="$options.freeSet.cilMeh" />
                                                </CButton>
                                            </span>
                                        </div>
                                        <div slot="estado" slot-scope="documento">
                                                <span v-if="!documento">
                                                    <CBadge shape="pill" color="secondary">
                                                        Sin Estado
                                                    </CBadge>
                                                </span>
                                                <span v-else>
                                                    <CBadge shape="pill" :color="getColorEstadoDocumento(documento)">
                                                        {{$utils.getEstadoDocumento(documento)}}
                                                    </CBadge>
                                                </span>
                                            </div>


                                    </a-table>
                                </CCol>
                            </CRow>

                        </div>
                        <div v-else-if="tipoDocumentoSelected.valor == 'contratos'"
                            slot="expandedRowRender"
                            slot-scope="trabajador" style="width:99%; margin: 0">
                            <CRow>
                                <CCol>
                                    <a-table
                                        row-key="idDocumento"
                                        :columns="columnasArchivosContratos" 
                                        :data-source="trabajador.documentos"
                                        :scroll="{ y: 400 }"
                                        class="tableInterior mt-3" 
                                        size="small">

                                        <div slot="tipoDoc" slot-scope="documento">
                                            {{documento.tipo}}
                                        </div>

                                        <div slot="fechaEmision" slot-scope="documento">
                                            {{$utils.formatFechaDocumentoNormal(documento.fechaEmision)}}
                                        </div>

                                        <div slot="fechaContrato" slot-scope="documento">
                                            {{$utils.formatFechaDocumentoNormal(documento.fechaContrato)}}
                                        </div>

                                        <div slot="fechaTermino" slot-scope="documento">
                                            {{$utils.formatFechaDocumentoNormal(documento.fechaTermino)}}
                                        </div>
                                        
                                        <div slot="fechaVencimiento" slot-scope="documento">
                                            {{$utils.formatFechaDocumentoNormal(documento.fechaVencimiento)}}
                                        </div>
                                        
                                        <div slot="estado" slot-scope="documento">
                                            <span v-if="!documento.documento">
                                                <CBadge shape="pill" color="secondary">
                                                    Sin Estado 
                                                </CBadge>
                                            </span>
                                            <span v-else>
                                                <CBadge shape="pill" :color="getColorEstadoDocumento(documento.documento)">
                                                    {{ $utils.getEstadoDocumento(documento.documento) }}                                                               
                                                </CBadge>
                                            </span>
                                        </div>

                                        <div slot="documentos" slot-scope="documento">
                                            
                                            <span v-if="documento.isGenerando || empresa.isLoadingGenerarDocumento || trabajador.isLoadingGenerarDocumento">
                                                <CIcon :content="$options.freeSet.cilLoopCircular" class="imgr" />
                                            </span>
                                            <div v-else>
                                                <CButton
                                                    v-if="documento.documento == null"
                                                    size="sm" variant="ghost"
                                                    content="Sin Archivo"
                                                        v-tippy="{ 
                                                            placement: 'top',
                                                            arrow: true, 
                                                            arrowType: 'round', 
                                                            animation:'fade',
                                                            theme: 'google',
                                                            followCursor : true,
                                                        }"
                                                    >
                                                    <CIcon
                                                        :content="$options.freeSet.cilMeh"
                                                    />
                                                </CButton>
                                                <span v-else>
                                                    <span v-show="!portContador ? documento.documento.estadoDocumento == 1 || documento.documento.estadoDocumento ==3 : true">
                                                        <a 
                                                            :href="documento.documento.urlDocumento" 
                                                            target="_blank" 
                                                            style="text-decoration: none; color:#0b7689;" 
                                                            content="Descargar Documento"
                                                            v-tippy="{ 
                                                                placement: 'top',
                                                                arrow: true, 
                                                                arrowType: 'round', 
                                                                animation:'fade',
                                                                theme: 'google',
                                                                followCursor : true,
                                                            }"
                                                        >
                                                            <CIcon :content="$options.freeSet.cilCloudDownload" />
                                                        </a>
                                                        /
                                                        <a
                                                            style="text-decoration: none; color:#0b7689"
                                                            content="Ver Documento"
                                                            v-tippy="{ 
                                                                placement: 'top',
                                                                arrow: true, 
                                                                arrowType: 'round', 
                                                                animation:'fade',
                                                                theme: 'google',
                                                                followCursor : true,
                                                            }"
                                                            @click="seleccionarTrabajadores(empresa, trabajador, documento.documento)">
                                                            <CIcon :content="$options.freeSet.cilZoomIn" />
                                                        </a>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </a-table>
                                </CCol>
                            </CRow>
                        </div> 
                </a-table>
            </CCol>
        </CRow>
        <ModalPdfViewDocs
            :prevue="mostrarVisorPDF"
            :trabajadorprev="trabajadorSelectedModal"
            :empresa="empresaSelModal"
            :documento="documentoSelectedModal"
            :periodoSelected="periodoSelected"
            :mesSelected="mesSelected"
            :tipoDocumentoSelected="tipoDocumentoSelected"
            :web="this"
        />
        <Modal :enableClose="false" v-model="envioDocs" title="Seleccionar documentos a enviar">
            <div class="vm-content" v-if="trabEnviar != null">
                <CRow class="mb-2">
                    <CCol md="2" class="text-center font-weight-bold">Enviar</CCol>
                    <CCol md="10" class="font-weight-bold">Información del Documento</CCol>
                </CRow>
                
                <CDropdownDivider />
                
                <div v-for="documento in trabEnviar.documentos" :key="documento.idDocumentoCtro">
                    <CRow>
                        <CCol md="2">
                            <CRow>
                                <CCol>
                                    <div v-if="documento.documento != null" class="text-center">
                                        <CIcon v-if="documento.documento != null" class="ml-2" style="color: #f9b115" v-show="documento.documento &&
                                        documento.estadoDocumento != 3" 
                                        v-c-tooltip.hover="{
                                            content: 'El documento no está aceptado',
                                            placement: 'left',
                                        }" :content="$options.freeSet.cilWarning" />
                                        <CSwitch
                                            :disabled="documento.estadoDocumento != 3"
                                            v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                            width="150px"
                                            class="mx-1 align-middle mb-2"
                                            shape="pill"
                                            variant="outline"
                                            :checked="documento.selecEnviar"
                                            @update:checked="seleccionarDocEnviar(documento)"
                                        />
                                    </div>
                                    <div v-else class="text-center align-middle">
                                        <CButton
                                           size="sm" variant="ghost"
                                            content="Sin Documento"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }"
                                            >
                                            <CIcon
                                                :content="$options.freeSet.cilMeh"
                                            />
                                        </CButton>
                                    </div>
                                </CCol>
                            </CRow>
                        </CCol>
                        <CCol  md="10">
                            <CRow>
                                <CCol>
                                    <span class="font-weight-bold">Documento</span> {{documento.tipo}}
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol>
                                    <span class="font-weight-bold">Tipo Contrato</span> {{documento.tipoContrato}}
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol>
                                    <span class="font-weight-bold">Fecha Contrato</span> {{$utils.formatFechaDocumentoNormal(documento.fechaContrato)}}
                                </CCol>
                            </CRow>
                            <CRow v-if="documento.documento != null && documento.documento.enviadoTrabajador">
                                <CCol>
                                    <span class="font-weight-bold">Documento enviado a trabajador</span> {{$utils.formatFechaDocumento(documento.documento.fechaEnvioATrabajador)}} 
                                </CCol>
                            </CRow>
                        </CCol>
                    </CRow>
                    <CDropdownDivider />
                </div>
            </div>
            <div class="vm-footer">
                <CButton size="sm" color="success"
                    :disabled="isEnviandoDocumentos || docsSeleccionadosEnviar.length == 0"
                    @click="enviarDocumentoTrabajadorModal">
                    <CIcon 
                    :content="isEnviandoDocumentos?$options.freeSet.cilLoopCircular:$options.freeSet.cilEnvelopeOpen"
                    :class="isEnviandoDocumentos?'imgr':''"
                    /> {{isEnviandoDocumentos?'Enviando':'Enviar'}}
                </CButton>

                <CButton class="float-right" size="sm" color="danger" 
                :disabled="isEnviandoDocumentos" 
                    @click="envioDocs = false">
                    <CIcon name="cil-x-circle"/> Salir
                </CButton>
            </div>
        </Modal>
    </div>
    
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    import cons from "@/const";
    import Multiselect from "vue-multiselect";
    import axios from "axios";
    import apiUrls from '@/views/utils/apiUrls'

    import VueModal from "@kouts/vue-modal";
    import "@kouts/vue-modal/dist/vue-modal.css";

    import { freeSet } from "@coreui/icons";
    
    import ModalPdfViewDocs from "./ModalPdfViewDocs";
    import OpcionesEnvioCorreo from "./tablasComponentes/opcionesEnvioCorreo";

    export default {
        name: "TablaEmpresas",
        freeSet,
        props: {
            portContador: Boolean,
            empresa: Object,
            periodoSelected: Object,
            mesSelected: Object,
            periodoCerrado: Boolean,
            trabajadores: Array,
            loading: Boolean,
            paginacion: Object,
            totalTrabajadores: Number,
            totalTrabajadoresDocumentos: Number,
            tipoDocumentoSelected: Object,
            isLoadingGenerarDocumento: Boolean,
            revision: Object,
            mostrarPeriodo: Boolean
        },
        components: {
            Multiselect,
            'Modal': VueModal,
            ModalPdfViewDocs,
            OpcionesEnvioCorreo
        },
        data() {
            return {
                tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
                urlBase: cons.port+"://" + cons.ipServer,
                
                empresaSelModal: null,
                trabajadorSelectedModal: {},
                documentoSelectedModal: null,
                mostrarVisorPDF: false,
                
                
                envioDocs: false,
                trabEnviar: null,
                docsSeleccionadosEnviar: [],
                isEnviandoDocumentos: false,
                
                localeTrabajadores: {
                    emptyText: ' '
                },
                columnasTrabajadores: [
                    { title: 'Rut', scopedSlots:{ customRender: 'rutTrabajador' }, key: 'rutTrabajador', width: '10%', ellipsis: true},// width: '10%'
                    { title: 'Trabajador', dataIndex: 'nombreTrabajador', key: 'nombreTrabajador',  width: '30%', ellipsis: true},// width: '20%'
                    //{ title: 'Última Generación', key: 'generacion', scopedSlots: { customRender: 'generacion' }, width: '20%', align: 'center', ellipsis: true},
                    //{ title: 'Estado Dcto.', key: 'estadoDocumento', scopedSlots: { customRender: 'estadoDocumento' }, width: '10%', align: 'center', ellipsis: false},
                    { slots: {title: 'tituloDoc'}, key: 'documento', scopedSlots: { customRender: 'documento' }, ellipsis: false, width: '20%', align: 'center'},// width: '10%'
                    {title: "Enviar Docto", scopedSlots: { customRender: 'envioCorreo' }, width: "40%", align: "center"}// width: '20%'
                ],

                columnasArchivosContratos: [
                    { title: 'Tipo Dcto.', scopedSlots: { customRender: 'tipoDoc' }, width: '10%', align: 'left' ,ellipsis: false},
                    { title: 'Fecha Emisión', scopedSlots:{ customRender: 'fechaEmision' }, width: '20%', align: 'center', ellipsis: false},
                    { title: 'Fecha Início Contrato', scopedSlots: { customRender: 'fechaContrato' }, width: '15%', align: 'left', ellipsis: false},
                    { title: 'Fecha Vencimiento', scopedSlots: { customRender: 'fechaVencimiento' }, width: '15%', align: 'center', ellipsis: false},
                    { title: 'Fecha Término', scopedSlots: { customRender: 'fechaTermino' }, width: '15%', align: 'center', ellipsis: false},
                    { title: 'Documentos', scopedSlots: { customRender: 'documentos' }, width: '15%', align: 'center', ellipsis: false},
                    { title: 'Estado', scopedSlots: { customRender: 'estado' }, width: '10%', align: 'center', ellipsis: false},
                ],
                
                columnasArchivosLiquidaciones: [
                    { title: 'Nombre', scopedSlots: { customRender: 'nombreArchivo' }, width: '20%', align: 'left' ,ellipsis: false},
                    { title: 'Fecha Emisión', scopedSlots:{ customRender: 'fechaEmision' }, width: '20%', align: 'center', ellipsis: false},
                    { title: 'Documentos', scopedSlots: { customRender: 'documentos' }, width: '20%', align: 'center', ellipsis: false},
                    { title: 'Estado', scopedSlots: { customRender: 'estado' }, width: '20%', align: 'center', ellipsis: false},
                ],

            

            };
        },
        created: function () {
        },
        beforeMount() {
            
        },
        methods: {
            eliminarDocumento: function(empresa, trabajador, documento){

                let url = `${this.urlBase}${apiUrls.remuneraciones.eliminarDocTrabajador}`

                documento.isLoadingDoc = true

                axios({
                    method: "POST",
                    "url": url,
                    "data": {
                        "tipoBd": "REMU",
                        "idEmpresa": empresa.idEmpresa,
                        "idDocumento": documento.idDocumento,
                        "mesPeriodo": (this.mesSelected != null) ? this.mesSelected.valor : null,
                        "anioPeriodo": (this.periodoSelected != null) ? this.periodoSelected.valor : null,
                        "tipoDocumento": (this.tipoDocumentoSelected != null) ? this.tipoDocumentoSelected.valor : null,
                        "filtrarPorPeriodo": this.mostrarPeriodo
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    if(trabajador.documento.idDocumento == documento.idDocumento){
                        trabajador.documento = null
                    }
                    trabajador.documentos.forEach(doc => {
                        if(doc.idDocumento == documento.idDocumento){
                            var index = trabajador.documentos.indexOf(doc)
                            trabajador.documentos.splice(index, 1)
                        }
                    })
                    this.mostrarNotificaciones(1, `${result.data.mensajeGeneral}`)

                },(error) => {
                        this.mostrarNotificaciones(3, `${error.response.data.mensajeGeneral}`)
                    }
                )
                .finally(() => {
                    if(documento != null){
                        documento.isLoadingDoc = false
                    }
                });

                /*if(trabajador.documento.idDocumento == documento.idDocumento){
                    trabajador.documento = null
                }
                trabajador.documentos.forEach(doc =>{
                    if(doc.idDocumento == documento.idDocumento){
                        var index = trabajador.documentos.indexOf(doc)
                        trabajador.documentos.splice(index, 1)
                    }

                    
                })*/
                /*axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "tipoBd": "REMU",
                        "idEmpresa": empresa.idEmpresa,
                        "idDocumento": documento.idDocumento,
                        "mesPeriodo": (this.mesSelected != null) ? this.mesSelected.valor : null,
                        "anioPeriodo": (this.periodoSelected != null) ? this.periodoSelected.valor : null,
                        "tipoDocumento": (this.tipoDocumentoSelected != null) ? this.tipoDocumentoSelected.valor : null,
                        "filtrarPorPeriodo": this.mostrarPeriodo
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.mostrarNotificaciones(3, `${result.data.mensajeGeneral}`)


                },(error) => {
                        this.mostrarNotificaciones(3, `${error.response.data.mensajeGeneral}`)
                        
                        
                    }
                )
                .finally(() => {
                });*/
            },
            descargarDocumento: function(doc){
                const link = document.createElement('a')
                link.href = doc.urlDocumento
                link.download = doc.nombreDocumento
                link.click()
                URL.revokeObjectURL(link.href)
            },
            seleccionarTrabajadores: function(empresa, trabajador, documento){
                
                this.empresaSelModal = empresa
                this.trabajadorSelectedModal = trabajador
                this.documentoSelectedModal = documento
                //this.getTrabajadoresModal()
                //this.trabajadorSelected = trabajador
                this.mostrarVisorPDF = true

            },
            handleTableChange: function(pagination, filters, sorter, filasActuales){
                pagination.empresa.paginacion = pagination;
                this.paginacion = pagination;
                //pagination.empresa.paginacion = pagination
                //this.revision.obtenerTrabajadores(this.empresa);
                this.$emit('obtenerTrabajadores', this.empresa);
            },
            generarDocumentoTrabajador: function(empresa, trabajador, documento){
                this.mostrarNotificaciones(3, "Generar!")
                
                /*if(documento != undefined){
                    documento.isGenerando = true
                }
                trabajador.isLoadingGenerarDocumento = true
                var url = cons.port+"://" + cons.ipServer + "/generar_documentos_tabajadores/"
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "info": {
                            "idEmpresa": empresa.idEmpresa,
                            "mesPeriodo": (this.mesSelected != null) ? this.mesSelected.valor : null,
                            "anioPeriodo": (this.periodoSelected != null) ? this.periodoSelected.valor : null,
                            "tipoDocumento": (this.tipoDocumentoSelected != null) ? this.tipoDocumentoSelected.valor : null,
                            "trabajadores": [trabajador.rutTrabajador],
                            "idDocumento": (documento != undefined)?documento.idDocumentoCtro:null,
                            "filtrarPorPeriodo": this.mostrarPeriodo
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {

                    console.log(result.data)
                    this.$notification.success(
                        result.data.mensaje,
                        {timer: 10, position: "bottomRight"}
                    );
                    if(documento != undefined){
                        
                        var hayGenerando = false

                        trabajador.documentos.forEach(docto => {
                            if(docto.idDocumentoCtro == result.data.docTrabajador.idCtro){
                                documento.documento = result.data.docTrabajador.documento
                            }

                            if(documento.isGenerando)
                                hayGenerando = true
                        });
                        
                        documento.isGenerando = false

                        if(!hayGenerando){
                            trabajador.isLoadingGenerarDocumento = false
                        }
                    } else{
                        var hayGenerando = false
                        trabajador.documentos.forEach(docto => {
                            if(docto.isGenerando)
                                hayGenerando = true
                        });
                        if(!hayGenerando){
                            trabajador.isLoadingGenerarDocumento = false
                        }

                        this.revision.obtenerTrabajadores(true, empresa)
                    }

                }, error => {
                    if(documento != undefined)
                        documento.isGenerando = false

                    var hayGenerando = false
                    trabajador.documentos.forEach(docto => {
                        if(docto.isGenerando)
                            hayGenerando = true
                    });
                    if(!hayGenerando){
                        trabajador.isLoadingGenerarDocumento = false
                    }
                    this.mensajeNotificacion = "Ups, Tuvimos un problema al obtener los Documentos, si el problema persiste contacte con nuestro soporte, Gracias."
                    this.$notification.error(error.response.data.mensaje,{ timer: 10, position: "bottomRight" });
                })
                .finally(() => {
                    if(documento != undefined)
                        documento.isGenerando = false

                    var hayGenerando = false
                    trabajador.documentos.forEach(docto => {
                        if(docto.isGenerando)
                            hayGenerando = true
                    });
                    if(!hayGenerando){
                        trabajador.isLoadingGenerarDocumento = false
                    }
                });*/

            },
            enviarDocumentoTrabajador: function(trabajador, idEmpresa){
                trabajador.documento.isLoadiongEnvio = true
                var url = cons.port + "://" + cons.ipServer + "/" + "enviar_documento_trabajador/"
                
                axios({
                    method: "POST",
                    url: url,
                    "data": {
                        "trabajadoresEnviar": {
                        "enviarTodos": false,
                        "idEmpresa": idEmpresa,
                        "anioPeriodo": this.periodoSelected.valor,
                        "mesPeriodo": this.mesSelected.valor,
                        "tipoDoc": this.tipoDocumentoSelected.valor,
                        "trabajadores":[{"rutTrabajador": trabajador.rutTrabajador}]
                        }
                    },
                    headers: {
                        Authorization: `${this.tokenLogin}`,
                    },
                    })
                    .then((result) => {
                        this.$notification.success(
                        result.data.mensaje,
                        { timer: 10, position: "bottomRight" }
                        );
                    },
                    (error) => {
                        this.$notification.error(
                        error.response.data.mensaje,
                        { timer: 10, position: "bottomRight" }
                        );
                    }
                    )
                    .finally(() => {
                        trabajador.documento.isLoadiongEnvio = false
                        //this.revision.obtenerTrabajadores(this.empresa)
                        this.$emit('obtenerTrabajadores', this.empresa);
                    });
            },
            enviarDocumentoTrabajadorModal: function(){
                this.isEnviandoDocumentos =  true
                let idDocumentosEnviar = []
                this.docsSeleccionadosEnviar.forEach(documento => {
                    idDocumentosEnviar.push(documento.idDocumentoCtro)
                });

                var url =  this.urlBase + "/enviar_documentos_trabajador/"

                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "info": {
                            "mes": (this.mesSelected != null) ? this.mesSelected.valor : null,
                            "anio": (this.periodoSelected != null) ? this.periodoSelected.valor : null,
                            "trabajador": this.trabEnviar,
                            "idDocumentosEnviar": idDocumentosEnviar,
                            "tipoDocumentos": (this.tipoDocumentoSelected != null) ? this.tipoDocumentoSelected.valor : null,
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.mostrarNotificaciones(1, result.data.mensajeGeneral)
                    
                    this.isEnviandoDocumentos =  false
                }, error => {
                    this.mostrarNotificaciones(3, error.response.data.mensajeGeneral)
                    this.isEnviandoDocumentos =  false
                })
                .finally(() => {
                });
            },

            enviarDocumentosTrabajadorModal: function(trabajador){
                    this.envioDocs = true
                    this.trabEnviar = trabajador
                    this.docsSeleccionadosEnviar = []

            },

            seleccionarDocEnviar: function(documento){
                documento.selecEnviar = !documento.selecEnviar
                if(documento.selecEnviar)
                    this.docsSeleccionadosEnviar.push(documento)
                else{
                    var index = this.docsSeleccionadosEnviar.indexOf(documento)
                    this.docsSeleccionadosEnviar.splice(index, 1)
                }
            },
            getColorEstadoDocumento: function(documento){
                switch(documento.estadoDocumento) {
                    case 1: return "info"
                    case 2: return "danger"
                    case 3: return "success"
                    default: return "secondary"
                }

            },
            /*getEstadoDocumento: function(documento){
                if(documento.hayDocumento){
                    switch(documento.estadoDocumento) {
                        case 1: return "En Revisión"
                        case 2: return "Rechazado"
                        case 3: return "Aceptado"
                        default: return "Sin Enviar"
                    }
                } else{
                    return "Sin Documento"
                }
            }*/

        },
    };
</script>


<style scoped>
.ant-table-wrapper{
    min-height: 400px;
}

.tippy-tooltip.google-theme{
  background-color:#29235C;
  font-weight: normal;
}
.tippy-tooltip.google-theme .tippy-backdrop {
  
  background-color:#29235C;
}
.tippy-content{
  background-color: transparent;
}
</style>