<template>
    <div>
        <!--{{trabajador}}-->
        <span v-if="trabajador.documentos.length==0"
            content="Sin Documento"
            v-tippy="{ 
                placement: 'top',
                arrow: true, 
                arrowType: 'round', 
                animation:'fade',
                theme: 'google',
                followCursor : true,
            }">
            <CIcon :content="$options.freeSet.cilMeh" />
        </span>
        <span v-else>
            <span v-if="mostrarAlertas">
                <span v-show="!trabajador.tieneMailTrabajador && !trabajador.modificarMail"
                    content="El trabajador no tiene correo"
                    v-tippy="{ 
                        placement: 'top',
                        arrow: true, 
                        arrowType: 'round', 
                        animation:'fade',
                        theme: 'google',
                        followCursor : true,
                    }">
                    <CIcon
                        class="ml-2"
                        style="color:#e55353"
                        :content="$options.freeSet.cilWarning"
                    />
                </span>
                <span
                    v-show="revisarEstadoDoc(trabajador.documentos)"
                    content="Hay documentos sin aceptar"
                    v-tippy="{ 
                        placement: 'top',
                        arrow: true, 
                        arrowType: 'round', 
                        animation:'fade',
                        theme: 'google',
                        followCursor : true,
                    }">
                    <CIcon
                        class="ml-2"
                        style="color:#f9b115"
                        :content="$options.freeSet.cilWarning"
                    />
                </span>
            </span>
            <span v-if="mostrarEnvioCorreo"
                v-show="!revisarEstadoDoc(trabajador.documentos)"
                :content="mensajeEnviarCorreo(trabajador)"
                v-tippy="{ 
                    placement: 'top',
                    arrow: true, 
                    arrowType: 'round', 
                    animation:'fade',
                    theme: 'google',
                    followCursor : true,
                }">
                <CButton
                    :disabled="trabajador.isEnviandoMailTrab || !trabajador.tieneMailTrabajador || isLoadingEnvioTodos"
                    style="font-size:11px;"
                    class="btnPrincipalVapp ml-2"
                    @click="enviarDocumentoTrabajador(trabajador, empresa.idEmpresa)"
                >   
                    <CIcon v-show="!trabajador.isEnviandoMailTrab" :content="$options.freeSet.cilEnvelopeOpen" />
                    <CIcon v-show="!trabajador.isEnviandoMailTrab" :content="$options.freeSet.cilExpandRight"  />
                    <CIcon v-show="trabajador.isEnviandoMailTrab" :content="$options.freeSet.cilLoopCircular" class="imgr" />
                </CButton>
            </span>
        </span>
        <span v-if="mostrarEnvioCorreo" v-show="portContador">
            <span>
                <CButtonGroup size="sm" class="mx-1"
                    :disaled="trabajador.isEnviandoMailTrab"
                    v-show="!trabajador.isModificandoMail && !trabajador.modificarMail"
                    :content="(trabajador.modificarMail)?'Cancelar':'Modificar Correo'"
                    v-tippy="{ 
                        placement: 'top',
                        arrow: true, 
                        arrowType: 'round', 
                        animation:'fade',
                        theme: 'google',
                        followCursor : true,
                    }"
                >
                    <CButton color="warning" size="sm" @click="trabajador.modificarMail = true">
                        <CIcon name="cil-pencil"/>
                    </CButton>
                </CButtonGroup>
            </span>
            <span>
                <CInput
                    v-model="trabajador.mailModificado"
                    type="text"
                    horizontal
                    placeholder="Correo"
                    :is-valid="trabajador.mailValido"
                    v-show="trabajador.modificarMail && !trabajador.isModificandoMail"
                    @keyup="validarMail(trabajador)">
                    <template #append>
                        <CButton 
                            color="success" 
                            size="sm"
                            :disabled="!trabajador.mailValido"
                            @click="cambiarCorreoTrabajador(trabajador, empresa.idEmpresa)"
                            content="Guardar Correo"
                            v-tippy="{ 
                                placement: 'top',
                                arrow: true, 
                                arrowType: 'round', 
                                animation:'fade',
                                theme: 'google',
                                followCursor : true,
                            }">
                            <CIcon name="cil-check-circle"/>
                        </CButton>
                        <CButton
                            :content="(trabajador.modificarMail)?'Cancelar':'Modificar Correo'"
                            v-tippy="{ 
                                placement: 'top',
                                arrow: true, 
                                arrowType: 'round', 
                                animation:'fade',
                                theme: 'google',
                                followCursor : true,
                            }"
                            v-show="trabajador.modificarMail"
                            color="danger" 
                            @click="trabajador.modificarMail = false" size="sm" >
                            <CIcon name="cil-x-circle"/>
                        </CButton>
                    </template>
                </CInput>
            </span>
        </span>
    </div>
</template>
<script>
    import cons from "@/const";
    import axios from "axios";
    import apiUrls from '@/views/utils/apiUrls'

    import { freeSet } from "@coreui/icons";

    export default {
        name: "OpcionesEnvioCorreo",
        freeSet,
        props: {
            mostrarAlertas: Boolean,
            mostrarEnvioCorreo: Boolean,
            empresa: Object,
            isLoadingEnvioTodos: Boolean,
            tipoDocumentos: Object,
            trabajador: Object,
            portContador: Boolean,
            mesSelected: Object,
            anioSelected: Object,
            filtrarPorPeriodo: Boolean,
        },
        components: {
        },
        data() {
            return {
                tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
                urlBase: cons.port+"://" + cons.ipServer,
                regMail: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
                mostrarModificarCorreo: false,
            

            };
        },
        created: function () {},
        beforeMount() {},
        methods: {
            revisarEstadoDoc: function(documentos){
                let haySinAceptar = false
                documentos.forEach(doc => {
                    if(this.tipoDocumentos.valor == "contratos"){
                        if(doc.documento != null && doc.documento.estadoDocumento != 3){
                            haySinAceptar = true
                            return
                        }
                    } else{
                        if(doc.estadoDocumento != 3){
                            haySinAceptar = true
                            return
                        }
                    }
                });
                return haySinAceptar
            },
            validarMail: function(trab){
                if(trab.mailModificado == "" || trab.mailModificado == null){
                    trab.mailValido = false
                } else{
                    trab.mailValido =  this.regMail.test(trab.mailModificado)
                }

            },
            cambiarCorreoTrabajador: function(trabajador, idEmpresa){

                if(trabajador.mailValido){
                    trabajador.isModificandoMail = true
                    let url = `${this.urlBase}${apiUrls.general.cambioCorreoTrabajador}`

                    axios({
                        method: "POST",
                        url: url,
                        "data": {
                            "idEmpresa": idEmpresa,
                            "rutTrabajador": trabajador.rutTrabajador,
                            "mailTrabajador": trabajador.mailModificado,
                        },
                        headers: {Authorization: `${this.tokenLogin}`},
                    })
                    .then((result) => {

                        trabajador.modificarMail = false
                        trabajador.tieneMailTrabajador = true
                        this.mostrarNotificaciones(1, result.data.mensajeGeneral)
                        
                        },
                        (error) => {
                            this.mostrarNotificaciones(3,  error.response.data.mensajeGeneral)
                        }
                    )
                    .finally(() => {
                        trabajador.isModificandoMail = false
                    });

                } else{
                    this.mostrarNotificaciones(3, "Correo Invalido")
                }  
            },
            enviarDocumentoTrabajador: function(trabajador, idEmpresa){
               
                trabajador.isEnviandoMailTrab = true
                let url = `${this.urlBase}${apiUrls.general.enviarDocumentoTrabajador}`
                
                axios({
                    method: "POST",
                    url: url,
                    "data": {
                        "trabajadoresEnviar": {
                            "enviarTodos": false,
                            "idEmpresa": idEmpresa,
                            "anioPeriodo": (this.anioSelected != null) ? this.anioSelected.valor : 'null',
                            "mesPeriodo": (this.mesSelected != null) ? this.mesSelected.valor : 'null',
                            "tipoDoc": this.tipoDocumentos.valor,
                            "filtrarPorPeriodo": this.filtrarPorPeriodo,
                            "trabajadores":[{"rutTrabajador": trabajador.rutTrabajador}]
                        }
                    },
                    headers: {
                        Authorization: `${this.tokenLogin}`,
                    },
                })
                .then(
                    (result) => { this.mostrarNotificaciones(1, result.data.mensaje) },
                    (error) => { this.mostrarNotificaciones(3, error.response.data.mensaje) }
                )
                .finally(() => {
                    trabajador.isEnviandoMailTrab = false
                    //this.revision.obtenerTrabajadores(this.empresa)
                });
            },
            mensajeEnviarCorreo: function(trabajador){
                let mensaje = (trabajador.documento.enviadoTrabajador)?'Este documento ya a sido enviado al trabajador, volver a enviar': (!trabajador.tieneMailTrabajador)?'Trabajador sin correo':'Documento listo para enviar a trabajador'
                return `${mensaje} (${trabajador.mailModificado})`
            }
        },
    };
</script>